import React, { useState } from "react";
import { Link, animateScroll as scroll } from "react-scroll";  //ftw!

//images
import logo from "../images/ART_LOGO_Primary_H.svg";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  return (
    <div className="fixed md:sticky top-0 w-full md:w-1/6 bg-white z-20">
      {/* Mobile Sticky Header */}
      <div className={`${
        isExpanded ? `hidden` : `flex`
      } md:hidden py-4 mx-10`}
      >
        <Link className="no-underline w-1/4 mt-3" to="/" onClick={() => scroll.scrollToTop()}>
          <img
            alt="pansasas brand logo"
            className="block mx-auto w-full"
            src={logo}
          />
        </Link>
        <button
          className="block absolute right-0 
            text-primary_color mx-5 h-16 w-16"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-10 w-12 float-right"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h10v2H0V3zm0 6h10v2H0V9zm0 6h10v2H0v-2z" />
          </svg>
        </button>
      </div>
      {/* Header - sidenav */}
      <header className={`${
        isExpanded ? `flex` : `hidden`
      } md:flex flex-col h-screen fixed md:sticky bg-white top-0 w-full z-10`}
      >
        <div className="hidden md:flex flex-row pl-4 pt-4 mt-12 mb-5 pb-5 md:mt-0 md:mb-0 w-11/12" id="sidenav">
          <Link className="no-underline cursor-pointer" to="/" onClick={() => scroll.scrollToTop()}>
            <img
              alt="brand logo"
              className="block mx-auto w-full"
              src={logo}
            />
          </Link>
        </div>
        <div className="flex md:hidden w-3/4 mx-10">
          <button
              className="block absolute top-0 right-0 my-7 mx-10 text-primary_color"
              onClick={() => toggleExpansion(!isExpanded)}
          >
              <svg
                className="fill-current float-right h-10 w-10"
                viewBox="0 0 96 96"
                xmlns="http://www.w3.org/2000/svg"
              >
                <title>Close</title>
                <polygon points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
              </svg>
          </button>
        </div>
        <div className="p-4 mt-40 md:pt-0 md:-mt-px">
          <nav
            className={`${
              isExpanded ? `block` : `hidden`
            } md:flex flex-col`}
          >
            <ul className="grid grid-flow-row font-primary_font cursor-pointer text-3xl md:text-lg text-center
              text-heading_color md:text-left mainNav">
              <li>
                <Link to="intro" activeClass="text-second_color" spy={true} smooth={true}
                className="pt-0" 
                onClick={() => { toggleExpansion(!isExpanded); }}>intro</Link>
              </li>
              <li>
                <Link to="logo" activeClass="text-second_color" spy={true} smooth={true}
                className=""
                onClick={() => { toggleExpansion(!isExpanded); }}>logo</Link>
              </li>
              <li><Link to="color" activeClass="text-second_color" spy={true} smooth={true}
              className=""
              onClick={() => { toggleExpansion(!isExpanded); }}>color</Link></li>
              <li><Link to="typography" activeClass="text-second_color" spy={true} smooth={true}
              className=""
              onClick={() => { toggleExpansion(!isExpanded); }}>typography</Link></li>
            </ul>
          </nav>
        </div>
        <div className="p-4 absolute bottom-0">
          <p className="text-xs uppercase">v1.0 6/30/2020</p>
        </div>
      </header>
    </div>

  );
}
export default Header;